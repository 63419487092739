import React, { useEffect, useState } from "react";
import { getByRepairshopIdCustomCarcomponents } from "../../../services/API/Carcomponent.Services";
import { getStorageObject } from "../../../utils/LocalStorage";
import { Stack } from "@mui/material";
import DataTable from "./DataTable";

const CustomCarcomponentsComponent = ({ customCarcomponents }) => {
  return (
    <Stack alignItems={"center"}>
      {customCarcomponents && <DataTable carcomponents={customCarcomponents} />}

      {/* {customCarcomponents &&
        customCarcomponents.map((carcomponent) => (
          <div key={carcomponent.id}>
            {carcomponent.name} {carcomponent.caducidad_meses + carcomponent.caducidad_km}
          </div>
        ))} */}
    </Stack>
  );
};

export default CustomCarcomponentsComponent;
