import React, { useEffect, useState } from "react";
import { Button, Container, Stack, Typography } from "@mui/material";
import PersonIcon from "@mui/icons-material/Person";
import { useAtom } from "jotai";
import { demoAtom } from "../../context/atoms/Atoms.Storage.jsx";
import { getAllByRepairshopIdUsers } from "../../services/API/User.Services.js";
import { getAllByRepairshopIdVehicles } from "../../services/API/Vehicle.Services.js";
import HeaderComponent from "../../components/Header.Component";
import MainContent from "../../components/MainContent.component.jsx";
import LoadingComponent from "../../components/Loading.Component";
import WhatsappModal from "../../components/chatModalComponent/Whatsapp.Modal.jsx";
import DataTable from "./components/DataTable.jsx";
import clientPageUsers from "../../utils/demoData/clientPageUsers.json";
import clientPageVehicles from "../../utils/demoData/clientPageVehicles.json";
import { getStorageObject } from "../../utils/LocalStorage.js";
import { useNavigate } from "react-router-dom";
import NoInterventionsComponent from "../../components/noInterventions/NoInterventions.Component.jsx";

const ClientsPage = () => {
  const navigate = useNavigate();

  // STATES

  const [loadingState, setLoadingState] = useState(true);
  const [usersState, setUsersState] = useState([]);
  const [vehiclesState, setVehiclesState] = useState([]);
  const [searchValueState, setSearchValueState] = useState();
  const [filteredUsersState, setFilteredUsersState] = useState();
  const [filteredVehiclesState, setFilteredVehiclesState] = useState();
  const [WhatsappModalIsOpenState, setWhatsappModalIsOpenState] = useState(false);
  const [userNameState, setUserNameState] = useState();
  const [userPhoneState, setUserPhoneState] = useState();

  const [demo] = useAtom(demoAtom);

  useEffect(() => {

    if (demo === true) {

      setUsersState(clientPageUsers);
      setVehiclesState(clientPageVehicles);
      setFilteredUsersState(clientPageUsers);
      setFilteredVehiclesState(clientPageVehicles);
      setLoadingState(false);

    } else if (demo === false) {

      const token = getStorageObject("token");

      setUsersState(usersState);
      setFilteredUsersState(usersState);
      setVehiclesState(vehiclesState);

      getAllByRepairshopIdVehicles(token)
        .then(({ data: vehicles }) => {

          getAllByRepairshopIdUsers(token)
            .then(({ data: users }) => {
              setUsersState(users);
              setFilteredUsersState(users);
              setFilteredVehiclesState(vehicles);
              setVehiclesState(vehicles);
              setLoadingState(false);
            });

        }).catch(error => {
          setUsersState([]);
          setVehiclesState([]);
          setLoadingState(false);
        });
    }
  }, [demo]);

  useEffect(() => {
    if (searchValueState) {
      const filteredUsersState = usersState.filter((user) => JSON.stringify(user).toLocaleLowerCase().includes(searchValueState.toLowerCase()));

      if (filteredUsersState.length === 0) {
        const filteredVehiclesState = vehiclesState.filter((vehicles) => JSON.stringify(vehicles).toLocaleLowerCase().includes(searchValueState.toLowerCase()));
        const userIdsWithFilteredVehicles = new Set(filteredVehiclesState.map((vehicle) => vehicle.id_user_owner));
        const filteredUsersState = usersState.filter((user) => userIdsWithFilteredVehicles.has(user.id));

        setFilteredUsersState(filteredUsersState);
        setFilteredVehiclesState(filteredVehiclesState);
      } else {
        const filteredUsersState = usersState.filter((user) => JSON.stringify(user).toLocaleLowerCase().includes(searchValueState.toLowerCase()));
        setFilteredUsersState(filteredUsersState);
      }
    }
  }, [searchValueState]);

  if (loadingState) {
    return <LoadingComponent />;
  }

  return (
    <MainContent>
      {WhatsappModalIsOpenState && <WhatsappModal userName={userNameState} userPhone={userPhoneState} onClose={() => setWhatsappModalIsOpenState(false)} />}
      {vehiclesState && usersState && (
        <Stack direction="column" gap="30px" width="100%" sx={{ margin: "0" }}>
          <HeaderComponent text="Mis clientes" icon={<PersonIcon sx={{ color: "#e94e1b", height: "40px", width: "40px" }} />} />
          <Container sx={{ padding: "0px 10px 10px 10px" }}>
            {usersState && usersState.length > 0 ? (
              vehiclesState && (
                <DataTable
                  setWhatsappModalIsOpen={setWhatsappModalIsOpenState}
                  setUserName={setUserNameState}
                  setUserPhone={setUserPhoneState}
                  filteredUsers={searchValueState ? filteredUsersState : usersState}
                  vehicles={searchValueState && !filteredVehiclesState ? filteredVehiclesState : vehiclesState}
                  searchValue={searchValueState}
                  setSearchValue={setSearchValueState}
                />
              )
            ) : (
              <Stack sx={{ justifyContent: "center", alignItems: "center", maxWidth: "40em", margin: "auto" }}>
                <NoInterventionsComponent />
              </Stack>
            )}
          </Container>
        </Stack>
      )}
    </MainContent>
  );
};

export default ClientsPage;
