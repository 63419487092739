import { ApiCall_GET, ApiCall_PATCH, ApiCall_POST } from "..";

export const getInvoice = async (_token, invoiceUrl) => {
  const response = await ApiCall_GET("/api/getinvoice", _token, { invoice_url: invoiceUrl });
  return response;
}

export const getByIdIntervention = async (_token, _id_repairShop, _id_user_owner, _interventionId) => {
  const response = await ApiCall_GET(`/api/getByIdIntervention`, _token, { id_user_owner: _id_user_owner, id_repairShop: _id_repairShop, interventionId: _interventionId });
  return response;
}

export const confirmIntervention = async (_token, _interventionData) => {
  const response = await ApiCall_PATCH("/api/confirmintervention", _interventionData, _token);
  return response;
}

export const getPendingInterventionCount = async (_token) => {
  const response = await ApiCall_GET(`/api/getpendinginterventioncount?`, _token);
  return response;

}

export const createManualIntervention = async (_token, _interventionData) => {
  const response = await ApiCall_POST("/api/createmanualintervention", _interventionData, _token);
  return response;
}

export const getInterventionsByVehicleId = async (_token, _vehicleId) => {
  const response = await ApiCall_GET(`/api/getInterventionsByVehicleId`, _token, { id_vehicle: _vehicleId });
  return response;
}

export const getByRepairshopIdInterventions = async (_token, _repairshopId) => {
  const response = await ApiCall_GET(`/api/getbyrepairshopidinterventions?id_repairshop=${_repairshopId}`, _token);
  return response;
}


export const getOpenedInterventions = async (_token, _userId) => {
  const response = await ApiCall_GET(`/api/getOpenedInterventions`, _token, { userId: _userId });
  return response;
}

export async function SetVehicleIntervention({ _user_owner_id, _repairshop_id, _interventionId, _userVehicle, _token, _callback, _callbackError }) {

  try {

    const body = {
      user_owner_id: _user_owner_id,
      repairshop_id: _repairshop_id,
      interventionId: _interventionId,
      userVehicle: _userVehicle
    };

    const result = await ApiCall_POST("/fire/setVehicleIntervention", body, _token);
    const interventionResult = result;

    if (interventionResult.message === "OK") {
      if (_callback) {
        _callback(interventionResult);
      }
    } else {
      if (_callbackError) {
        _callbackError(e);
      }

    }

  } catch (e) {
    if (_callbackError) {
      _callbackError(e);
    }

    console.error("Error updating document: ", e);

  }
}
