import * as React from "react";
import { useEffect, useState } from "react";
import CssBaseline from "@mui/material/CssBaseline";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import CheckoutComponent from "../Checkout.Component";
import ChangedItems from "../changedItems/ChangedItems.Component";
import Revisions from "../revisions/Revisions.Component";
import {
  changedItemsAtom,
  commentsInterventionAtom,
  dateInterventionAtom,
  kmInterventionAtom,
  modelInterventionAtom,
  manufacturerInterventionAtom,
  plateInterventionAtom,
  reviewsAtom,
  urlParam,
  userVehicleAtom,
  clientNameAtom,
  clientSurnameAtom,
  tokenAtom,
  demoAtom,
  fieldsMapAtom,
  vehicleInterventionAtom,
  checkInterventionsAtom,
} from "../../../../context/atoms/Atoms.Storage";

import { useAtom } from "jotai";
import { Grid, Container, Tooltip } from "@mui/material";
import SideBarComponent from "./SideBar.Component.jsx";
import { useRef } from "react";
import { getInterventionsByVehicleId, confirmIntervention, SetVehicleIntervention } from "../../../../services/API/Intervention.Services.js";
import { getAllCarcomponents } from "../../../../services/API/Carcomponent.Services.js";
import { getStorageObject, setStorageObject } from "../../../../utils/LocalStorage";
import { NormalizeId } from "../../../../utils/FormatValues";
import { WsCloseIntervention } from "../../../../clientHub/WebSocket.js";
import { disableNetwork } from "firebase/firestore";
import { GetAllAppointedBilling } from "../../../../services/API/Calendar.Services.js";

function MessageIntervention_created() {
  return (
    <Grid item xs={12} sx={{ p: "5em 0", textAlign: "center" }}>
      <Typography variant="h4" gutterBottom>
        Intervención creada.
      </Typography>
      <Typography variant="h6" gutterBottom sx={{ color: "#9E9E9E" }}>
        Ya puedes cerrar la pestaña
      </Typography>
    </Grid>
  );
}

function MessageIntervention_error() {
  return (
    <Grid item xs={12}>
      <Typography variant="h5" gutterBottom>
        Hemos encontrado un problema.
      </Typography>
      <Typography variant="subtitle1">Intenta refrescar tu navegador con la tecla F5 y efectua la intervención otra vez.</Typography>
    </Grid>
  );
}

function MessageChooser(
  steps,
  activeStep,
  modelInterventionState,
  manufacturerInterventionState,
  plateInterventionState,
  clientNameState,
  clientSurnameState,
  kmInterventionState,
  dateInterventionState,
  commentsInterventionState,
  isClosed,
  setActiveStep
) {
  const [jwtState] = useAtom(tokenAtom);
  const [urlParamState] = useAtom(urlParam);
  const [userVehicleState] = useAtom(userVehicleAtom);
  const [vehicleInterventionState] = useAtom(vehicleInterventionAtom);
  const [changedItemsState] = useAtom(changedItemsAtom);
  const [reviewsState] = useAtom(reviewsAtom);
  const [demo] = useAtom(demoAtom);
  const [carcomponentsState, setCarcomponents] = useState([]);
  const [fieldsMapState, setFieldsMapState] = useAtom(fieldsMapAtom);
  const [vehicleInterventionsState, setVehicleInterventionsState] = useState();
  const [checkInterventions, setCheckInterventions] = useAtom(checkInterventionsAtom);

  useEffect(() => {
    if (userVehicleState.id) {
      const token = getStorageObject("token") || jwtState;

      getAllCarcomponents(token)
        .then((carcomponents) => {

          getInterventionsByVehicleId(token, userVehicleState.id)
            .then((interventions) => {
              setVehicleInterventionsState(interventions.data);
              const carcomponentArray = [];

              carcomponents.data.forEach((carcomponent) => {
                let formattedCarcomponent = {
                  id: carcomponent.id,
                  label: carcomponent.name,
                  piece: carcomponent.is_piece,
                  fields: [
                    {
                      id: carcomponent.id,
                      label: "Caducidad en",
                      img: `ico_${NormalizeId(carcomponent.name)}`,
                      value: fieldsMapState.TextChange_Aceite_Motor,
                      defaultValues: { caducidadMeses: carcomponent.caducidad_meses, caducidadKm: carcomponent.caducidad_km, isReplaced: carcomponent.isReplaced },
                    },
                  ],
                };
                if (!formattedCarcomponent.piece) {
                  formattedCarcomponent.fields[0].defaultValues.status = interventions.length > 1 ? 3 : 0;
                }
                carcomponentArray.push(formattedCarcomponent);
              });

              setCarcomponents(carcomponentArray);
            });

        });
    }
  }, [userVehicleState.id]);

  const [disabled, setDisabled] = useState(false);

  useEffect(() => {

    disableConfirmIntervention();

  }, [modelInterventionState, manufacturerInterventionState, dateInterventionState, plateInterventionState, kmInterventionState, clientNameState, activeStep]);

  const disableConfirmIntervention = () => {
    if (activeStep === steps.length - 1) {

      if (modelInterventionState == "[object Object]" || modelInterventionAtom?.id === null || manufacturerInterventionState?.id === null || !manufacturerInterventionState || !modelInterventionState || manufacturerInterventionState === "[object Object]" || plateInterventionState === "[object Object]" || !kmInterventionState === "[object Object]" || !clientNameState) {
        setDisabled(true);
        return true;
      } else {
        setDisabled(false);
        return false;
      }
    } else {
      setDisabled(false);
      return false;
    }

  };

  const getStepContent = (step) => {
    switch (step) {
      case 0:
        return <ChangedItems carcomponents={carcomponentsState.filter((carcomponent) => carcomponent.piece)} dateIntervention={dateInterventionState} />;
      case 1:
        return <Revisions interventions={vehicleInterventionsState} carcomponents={carcomponentsState.filter((carcomponent) => !carcomponent.piece)} dateIntervention={dateInterventionState} />;
      case 2:
        return <CheckoutComponent carcomponents={carcomponentsState} />;
      default:
        throw new Error("Unknown step");
    }
  };

  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };

  const handleSubmit = async (event) => {
    let isDemo = false;

    if (urlParamState?.id === "pbaUyBfUeYSrqybS9cu2SqDlMml2" && activeStep === steps.length - 1) {
      isDemo = true;
      isClosed.current = true;

      if (urlParamState.isClientProfiler == "true") {
        WsCloseIntervention("OK");
      }

      setTimeout(() => {
        window.open(`https://drivimobility.com/intervencion/12?qty=49123`, "_self");
      }, 400);

    }


    if (demo && activeStep === steps.length - 1) {
      isDemo = true;
      isClosed.current = true;

      if (urlParamState.isClientProfiler) {
        WsCloseIntervention("OK");

        setTimeout(() => {
          window.open(`https://drivimobility.com/intervencion/12?qty=49123`, "_self");
        }, 400);
      }

    }


    if (!isDemo) {

      if (activeStep === steps.length - 1) {
        let vehicleCheckout = {
          id_vehicle_model: modelInterventionState || userVehicleState.model,
          id_vehicle_energy: userVehicleState.energy,
          id_vehicle_manufacturer: manufacturerInterventionState || userVehicleState.manufacturer,
          id_user_repairshop: userVehicleState.workshop.driviId,
          year: userVehicleState.year,
          plate: plateInterventionState || userVehicleState.plate,
          id: userVehicleState.id,
          km: Number(kmInterventionState ?? 0),
        };

        const interventionCheckout = {
          id: vehicleInterventionState.id,
          comments: commentsInterventionState,
          confirmed: true,
          plate: plateInterventionState,
          date: dateInterventionState,
          km: kmInterventionState,
        };

        const userCheckout = {
          id: userVehicleState.userIdOwner,
          name_first: clientNameState,
          name_last: clientSurnameState,
        };

        const pieces_result = changedItemsState.filter((item, index) => {
          if (item !== undefined && item.id !== "") {
            item.status = 0;
            return changedItemsState.findIndex((x) => x.id === item.id) === index;
          }
        });

        const revisions_result = reviewsState.filter((item, index) => {
          if (item !== undefined && item.id !== "") {
            return reviewsState.findIndex((x) => x.id === item.id) === index;
          }
        });

        const carcomponentCheckout = [...pieces_result, ...revisions_result];
        const carcomponentCheckoutFormatted = carcomponentCheckout.filter((x) => x !== undefined && x.status !== 3);

        const updateInterventionData = {
          user: userCheckout,
          vehicle: vehicleCheckout,
          carcomponents: carcomponentCheckoutFormatted,
          intervention: interventionCheckout,
        };

        if (urlParamState?.id === "1pbaUyBfUeYSrqybS9cu2SqDlMml2") {
          isClosed.current = true;

          if (urlParamState.isClientProfiler == "true") {
            WsCloseIntervention("OK");
          }

          setTimeout(() => {
            window.close();
          }, 300);

        }
        else {
          const token = getStorageObject("token") || jwtState;

          const getPageNumber = async (maxPages) => {
            let pageNumber = getStorageObject("confirmPageNumber") ? Number(getStorageObject("confirmPageNumber")) : 0;

            if (pageNumber === 0 || pageNumber >= maxPages) {
              pageNumber = 1;
            } else {
              pageNumber += 1;
            }

            event.preventDefault();

            const url = `https://drivimobility.com/intervencion/${pageNumber}`;

            try {
              const response = await fetch(url);

              if (response.ok) {
                setStorageObject("confirmPageNumber", pageNumber);
                return pageNumber;
              } else {
                setStorageObject("confirmPageNumber", 1);
                return 1;
              }
            } catch (error) {
              setStorageObject("confirmPageNumber", 1);

              return 1;
            }
          };

          const pageNumber = await getPageNumber(30);
          const prevAppointedBilling = await GetAllAppointedBilling(token, updateInterventionData.vehicle.id_user_repairshop);

          confirmIntervention(token, updateInterventionData)
            .then((result) => {
              GetAllAppointedBilling(token, updateInterventionData.vehicle.id_user_repairshop).then((postAppointedBilling) => {
                setCheckInterventions(!checkInterventions);

                if (result) {
                  isClosed.current = true;

                  if (urlParamState.isClientProfiler == "true") {
                    WsCloseIntervention("OK");
                  }

                  if (window.opener) {
                    window.opener.location.reload();
                  }

                  if (prevAppointedBilling && postAppointedBilling) {
                    window.open(`https://drivimobility.com/intervencion/${pageNumber}?qty=${postAppointedBilling.data}&from=${prevAppointedBilling.data}`, "_self");
                  } else {
                    window.open(`https://drivimobility.com/intervencion/${pageNumber}`, "_self");
                  }
                  
                }
              });
            })
            .catch((error) => {
              console.error("Error en el front al confirmar la internvención: ", error);
            });
        }
      }
    }

    setActiveStep(activeStep + 1);
    event.preventDefault();
  };

  const SubmitButton = (props) => <button {...props} type="submit" />;

  const conditionChooser = () => {
    if (activeStep === steps?.length) {
      if (isClosed) {
        return MessageIntervention_created();
      }
    } else if (isClosed.current === true) {
      return MessageIntervention_created();
    } else {
      return (
        <Grid id="MessageChooser_Grid" xs={12} sx={{ border: "none" }}>
          <form
            className="form"
            onSubmit={handleSubmit}
            style={{
              maxHeight: "85vh",
              display: "flex",
              flexDirection: "column",
              flexWrap: "nowrap",
              alignContent: "center",
              justifyContent: "flex-start",
              alignItems: "stretch",
              overflow: "auto",
              overflowX: "hidden",
            }}
          >
            {getStepContent(activeStep)}

            <Box
              sx={{
                height: "-webkit-fill-available",
                width: "-webkit-fill-available",
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "self-end",
                position: "absolute",
                pointerEvents: "none",
                p: { md: "0 2em 2em 0" },
              }}
            >
              {" "}
              <Box
                backgroundColor="white"
                sx={{ position: "relative", display: "flex", alignItems: "center", justifyContent: "center", padding: "1em", gap: "1em", mr: "1em", borderRadius: "5px", boxShadow: "0 0 30px 30px #fff" }}
              >
                {activeStep !== 0 && (
                  <Button
                    onClick={handleBack}
                    sx={{
                      color: "#ee4136",
                      pointerEvents: "auto",
                      backgroundColor: "#fff",
                      border: "1px solid #ee4136",
                      height: "3em!important",
                    }}
                  >
                    Volver
                  </Button>
                )}
                {disabled && <Tooltip open={true} style={{ position: "absolute", bottom: "-30px", right: "140px" }} title="Asegurate de que todos los datos estén intruducidos"></Tooltip>}
                <Button
                  component={SubmitButton}
                  disabled={disabled}
                  variant="contained"
                  sx={{
                    p: "0.5em 2em 0.5em 2em",
                    backgroundColor: "#ee4136",
                    border: "3px solid transparent",
                    pointerEvents: "auto",
                    height: "3em!important",
                    "&:hover": { background: "#fff", color: "#ee4136" },
                  }}
                >
                  {activeStep === steps.length - 1 ? "Confirmar Intervención" : "Siguiente"}
                </Button>
              </Box>
            </Box>
          </form>
        </Grid>
      );
    }
  };

  return conditionChooser();
}

export default function NavigatorComponent(_props) {
  const isMounted = useRef(false);
  const [activeStep, setActiveStep] = React.useState(0);

  const [modelInterventionState, setModelInterventionState] = useAtom(modelInterventionAtom);
  const [manufacturerInterventionState, setManufacturerlInterventionState] = useAtom(manufacturerInterventionAtom);
  const [plateInterventionState, setPlateInterventionState] = useAtom(plateInterventionAtom);
  const [kmInterventionState, setKmInterventionState] = useAtom(kmInterventionAtom);
  const [clientNameState, setClientNameState] = useAtom(clientNameAtom);
  const [clientSurnameState, setClientSurnameState] = useAtom(clientSurnameAtom);

  const [editModeState, setEditModeState] = useState(false);

  const [dateInterventionState, setDateInterventionState] = useAtom(dateInterventionAtom);

  const [commentsInterventionState, setCommentsInterventionState] = useAtom(commentsInterventionAtom);

  const steps = ["Piezas cambiadas", "Revisiones", "Confirmar intervención"];

  useEffect(() => {
    if (activeStep == 2) {
      setEditModeState(true);
    }
  }, [activeStep]);

  useEffect(() => {
    if (!isMounted.current) {
    }

    isMounted.current = true;
  }, [_props.isClosed.current]);

  return (
    <React.Fragment>
      <CssBaseline />
      <AppBar
        position="absolute"
        color="default"
        elevation={0}
        sx={{
          position: "relative",
          borderBottom: (t) => `1px solid ${t.palette.divider}`,
        }}
      ></AppBar>

      <Grid container xs={12}>
        <Grid
          id="SideBarComponent_container"
          xs={12}
          md={3}
          lg={3}
          xl={3}
          sx={{ height: { xs: "30vh", md: "100%" }, overflow: { xs: "auto", md: "hidden" }, p: "1em 0 1em 1em", margin: { xs: "0 0 2em 0", md: "0" } }}
        >
          <SideBarComponent editModeState={editModeState} setEditModeState={setEditModeState} isClosed={_props.isClosed?.current ?? false} activeStep={activeStep}></SideBarComponent>
        </Grid>

        <Grid container id="NavigatorComponent_container" xs={12} md={9} lg={9} sx={{ p: "2em 0 1em 1em" }}>
          <Grid id={"NavigatorComponent_container_overflow"} xs={12} sx={{ border: "none", borderRadius: "0.2em", overflow: "hidden" }}>
            <Container display="flex" alignItems="center" justifyContent="center" width="fit-content" textWrap="nowrap">
              <Stepper activeStep={activeStep} sx={{ pt: 0, pb: 5, width: "90%" }}>
                {steps.map((label) => (
                  <Step
                    key={label}
                    sx={{
                      "& .MuiStepIcon-root": {
                        color: "#ee4136!important",
                        fontSize: "1.5em",
                        scale: "1.098",
                      },
                      "& .MuiStepIcon-text": {
                        fontSize: "0,9em",
                      },
                    }}
                  >
                    <StepLabel
                      sx={{
                        "& .MuiStepLabel-label": {
                          fontSize: "1.15em",
                        },
                        "& .MuiStepLabel-label.Mui-active": {
                          backgroundColor: "#ee4136",
                          padding: "2px 13px",
                          borderRadius: "50px",
                          color: "#fff",
                        },
                      }}
                    >
                      {label}
                    </StepLabel>
                  </Step>
                ))}
              </Stepper>
            </Container>
            {MessageChooser(
              steps,
              activeStep,
              modelInterventionState,
              manufacturerInterventionState,
              plateInterventionState,
              clientNameState,
              clientSurnameState,
              kmInterventionState,
              dateInterventionState,
              commentsInterventionState,
              _props.isClosed,
              setActiveStep
            )}
          </Grid>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
