import React, { useEffect, useRef, useState } from 'react'
import SmsIcon from '@mui/icons-material/Sms';
import CloseIcon from "@mui/icons-material/Close";
import HeaderComponent from '../Header.Component';
import MainContent from '../MainContent.component';
import LoadingComponent from '../Loading.Component'

import { Button, IconButton, Link, Stack, Typography } from '@mui/material';
import { getByPhoneMessages } from '../../services/API/Whapi.Services';
import { getStorageObject } from '../../utils/LocalStorage';
import MessageComponent from './components/Message.Component';
import ModalComponent from '../Modal.Component';

import {useToast} from '../../components/toastComponent/toast.Actions'


const WhatsappModal = ({ onClose, userName, userPhone }) => {

  const toast = useToast()
  const scrollableDivRef = useRef(null);

  //STATES

  const [loadingState, setLoadingState] = useState(true)
  const [whapiState, setWhapiState] = useState()
  const [img, setImg] = useState();


  //USEEFFECTS


  useEffect(() => {
    const scrollableDiv = scrollableDivRef.current;
    if (scrollableDiv) {
      scrollableDiv.scrollTo({
        top: scrollableDiv.scrollHeight,
      });
    }
  }, [whapiState]);

  useEffect(() => {
    const token = getStorageObject("token");

    getByPhoneMessages(token, userPhone)
      .then(({ data: messages, status, ok }) => {

        const editedMessages = messages?.messages?.filter((message) => {

          if (message.type === "action") {
            if (message.action.type === "edit") {
              return message.action;
            }
          }
        });

        const reactionMessages = messages?.messages?.filter((message) => {

          if (message.type === "action") {
            if (message.action.type === "reaction") {
              return message.action;
          }
        }});


        if (editedMessages) {
          editedMessages.forEach((editedMessage) => {
            const messageToEdit = messages?.messages?.find((message) => message.id === editedMessage.action.target);
            if (messageToEdit && messageToEdit.type === "link_preview") {
              messageToEdit.link_preview.body = editedMessage.action.edited_content.body;
            }
            if (messageToEdit) {
              messageToEdit.text = editedMessage.action.edited_content.body;
            } else {
              console.warn(`Message with id ${editedMessage.action.target} not found`);
            }
          });
        
        }

        if (reactionMessages) {
          reactionMessages.forEach((reactionMessage) => {
            const messageToEdit = messages?.messages?.find((message) => message.id === reactionMessage.action.target);
            if (messageToEdit) {
              messageToEdit.emoji = reactionMessage.action.emoji;
            } else {
              console.warn(`Message with id ${editedMessage.action.target} not found`);
            }
          });
        
        }

        setWhapiState(messages?.messages?.toReversed());
        setImg(messages?.icon);
        setLoadingState(false);
      })
      .catch((error) => {
        toast.openCustomToast("Error al cargar la conversación", "error", 3000), setLoadingState(false), onClose();
      });
  }, []);

  //STYLES

  const handleError = () => {
    setImg("/img/defaultProfilePicture.svg");
  };

  //RENDER

  if (loadingState) {
    return <LoadingComponent />;
  }

  return (
    <ModalComponent>
      <Stack sx={{ width: "calc(100% - 50px)", backgroundColor: "white", height: "calc(100% - 50px)", boxShadow: "0 0 30px 0 rgba(0, 0, 0, 0.15)" }}>
        <Stack sx={{ flexDirection: "row", justifyContent: "space-between", padding: "10px 15px", boxShadow: "0 0 30px 0 rgba(0, 0, 0, 0.15)" }}>
          <Stack sx={{ flexDirection: "row", alignItems: "center", gap: "10px" }}>
            <img style={{ height: "35px", width: "35px", borderRadius: "100%", boxShadow: "0 0 5px 0 rgba(0, 0, 0, 0.5)" }} src={img} onError={handleError} />
            <Typography sx={{ fontWeight: "200", fontSize: "1.5rem" }}>{userName}</Typography>
            <Link sx={{ textDecoration: "none" }} href={`https://wa.me/34${userPhone.replace(/\D/g, "")}`} target="_blank">
              {" "}
              <Button variant="contained" sx={{ display: "flex", alignItems: "center", justifyContent: "center", gap: "5px", boxShadow: "0 0 5px 0 rgba(0, 0, 0, 0)" }}>
                Enviar mensaje <img style={{ height: "15px" }} src="/img/ico_whatsapp_white.svg" alt="" />
              </Button>
            </Link>
          </Stack>
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Stack>
        <Stack
          ref={scrollableDivRef}
          sx={{
            overflow: "auto",
            padding: "30px",
            height: "100%",
            gap: "20px",
          }}
        >
          {whapiState && whapiState?.length > 0 ? (
            whapiState?.map((message, index) => <MessageComponent message={message} key={index} />)
          ) : (
            <Stack sx={{ gap: "20px", width: "100%", minHeight: "100%!important", alignItems: "center", justifyContent: "center" }}>
              <img style={{ maxWidth: "200px" }} src="/img/drivi_logo.svg" alt="" />
              <Typography sx={{ fontSize: "2rem", fontWeight: "200", textAlign: "center" }}>Todavia no hay mensajes</Typography>
            </Stack>
          )}
        </Stack>
      </Stack>
    </ModalComponent>
  );
}

export default WhatsappModal