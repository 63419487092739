import React, { useEffect, useState } from "react";
import { Calendar, DateObject } from "react-multi-date-picker";
import DatePanel from "react-multi-date-picker/plugins/date_panel";
import "./OrangeDatePicker.css";
import { getStorageObject } from "../../../utils/LocalStorage";
import { getCalendarWorkloadData, postCalendarWorkloadData, deleteCalendarWorkload } from "../../../services/API/User.Services";
import { demoAtom } from "../../../context/atoms/Atoms.Storage";
import { useAtom } from "jotai";
import calendarDemoData from "../../../utils/demoData/calendarData.json";

export default function CustomDatePicker() {
  const [valueState, setValueState] = useState();
  const [demo, setDemo] = useAtom(demoAtom);
  const [responsiveSmallState, setResponsiveSmallState] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 1000) {
        setResponsiveSmallState(true);
      } else {
        setResponsiveSmallState(false);
      }
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (demo === true) {

      setValueState(calendarDemoData.calendarWorkload);

    } else if (demo === false) {
      const id = getStorageObject("repairShopId");
      const token = getStorageObject("token");

      getCalendarWorkloadData(token, id).then(({ data: datePickerData }) => {
        let workLoadData = [];

        datePickerData.map((range) => {
          let RangeObject = [new DateObject(range.date_start), new DateObject(range.date_end)];
          workLoadData.push(RangeObject);
        });

        setValueState(workLoadData);
      }).catch(error => { });
    }
  }, []);

  useEffect(() => {
    if (demo === false) {
      const id = getStorageObject("repairShopId");
      const token = getStorageObject("token");


      if (valueState && valueState.every((range) => Array.isArray(range) && range.length > 1)) {
        valueState.forEach((range) => {
          const data = {
            id_user_repairshop: id,
            date_start: range[0]?.toDate(),
            date_end: range[1]?.toDate(),
          };
          postCalendarWorkloadData(token, data).then((_data) => { });
        });

        if (!valueState[0]) {
          const id = getStorageObject("repairShopId");
          const token = getStorageObject("token");

          deleteCalendarWorkload(token, id).then((_data) => { });
        }
      }
    }
  }, [valueState]);

  useEffect(() => {
  }, [valueState]);

  const weekDays = ["DOM", "LUN", "MAR", "MIE", "JUE", "VIE", "SAB"];
  const months = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"];

  return (
    <>
      <Calendar
        range={true}
        multiple={true}
        rangeHover={true}
        style={{ borderRadius: "0px", boxShadow: "0 0 30px 0 rgba(0, 0, 0, 0.2)" }}
        weekDays={weekDays}
        months={months}
        weekStartDayIndex={1}
        className="orange rmdp-mobile"
        value={valueState}
        plugins={[<DatePanel colors={["red"]} className="orange" header="Fechas" position="right" />]}
        onChange={setValueState}
        numberOfMonths={responsiveSmallState ? 1 : 2}
      />
    </>
  );
}
