import { motion } from 'framer-motion'

const FadeInComponent = ({ index , children }) => {

    return (
        <motion.div key={index} initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 0.2 }}>
            {children}
        </motion.div>
    )
}

export default FadeInComponent