import React, { useEffect, useRef } from "react";
import { useAtom } from "jotai";
import CssBaseline from "@mui/material/CssBaseline";
import Grid from "@mui/material/Grid";
import NavigatorComponent from "./components/navigator/Navigator.Component.jsx";
import { carOwnerAtom, interventionEditModeAtom, tokenAtom, urlParam, userVehicleAtom, vehicleInterventionAtom } from "../../context/atoms/Atoms.Storage";
import { VehicleMapper } from "../../context/mappers/Vehicle.Mapper.jsx";
import { getByIdIntervention } from "../../services/API/Intervention.Services.js";
import { getStorageObject } from "../../utils/LocalStorage.js";

function InterventionPage(_props) {
  const { InitVehicleMapper } = VehicleMapper();

  // STATES
  const interventionIsClosedState = useRef();
  const [carOwnerState, setCarOwnerState] = useAtom(carOwnerAtom);
  const [userVehicleState, setUserVehicleState] = useAtom(userVehicleAtom);
  const [vehicleInterventionState, setVehicleInterventionState] = useAtom(vehicleInterventionAtom);
  const [urlParamState, setUrlParamState] = useAtom(urlParam);
  const [interventionEditMode, setInterventionEditMode] = useAtom(interventionEditModeAtom);
  const [jwtState, setJwtState] = useAtom(tokenAtom);

  // USE EFFECT

  useEffect(() => {
    if (urlParamState) {
      const token = getStorageObject("token") || jwtState;

      let params = new URLSearchParams(document.location.search);
      let userReapirShopId = params.get("id0");
      let userOwnerId = params.get("id1");
      let interventionId = params.get("id2");

      getByIdIntervention(token, userReapirShopId, userOwnerId, interventionId)
        .then(({ data, status, ok }) => {

          setCarOwnerState(data.user);

          if (data.vehicle) {
            data.vehicle.manufacturer = { id: data.vehicle.id_manufacturer, value: data.vehicle.name_manufacturer }
            data.vehicle.model = { id: data.vehicle.id_model, value: data.vehicle.name_model }
          }

          setUserVehicleState(InitVehicleMapper(data.vehicle));
          setVehicleInterventionState(data.intervention);

          if (interventionEditMode === true) {
            interventionIsClosedState.current = false;
            setInterventionEditMode(false);
          } else {
            if (data.intervention.confirmed) {
              interventionIsClosedState.current = true;
            } else {
              interventionIsClosedState.current = false;
            }
          }

        }).catch(error => { });
    }
  }, [urlParamState]);

  // RENDER

  return (
    <>
      <CssBaseline />
      <Grid container display="block" flexDirection="column" sx={{ height: { xs: "auto", sm: "100vh" }, overflow: { xs: "hidden", xl: "hidden" } }}>
        <Grid item xs={12}>
          <NavigatorComponent isClosed={interventionIsClosedState}></NavigatorComponent>
        </Grid>
      </Grid>
    </>
  );
}

export default InterventionPage;
