import React, { useState, useEffect } from "react";
import { useAtom } from "jotai";
import { Autocomplete } from "@mui/material";
import Button from "@mui/material/Button";
import { Grid, InputAdornment, TextField } from "@mui/material";
import Typography from "@mui/material/Typography";
import { getAllModels } from "../../../../services/API/Model.Services.js";
import { getAllManufacturers } from "../../../../services/API/Manufacturer.Services.js";

import { clientNameAtom, clientSurnameAtom, dateInterventionAtom, interventionEditModeAtom, kmInterventionAtom, manufacturerInterventionAtom, modelInterventionAtom, plateInterventionAtom, tokenAtom } from "../../../../context/atoms/Atoms.Storage";
import { getStorageObject } from "../../../../utils/LocalStorage.js";


const textFields_carModel_style = () => {
  return {
    width: "100%",
    "& input": {
      fontWeight: "bolder",
      textAlign: "left",
      fontSize: "1.4em",
    },
    "& fieldset": { border: "none" },
  };
};

const textFields_style = () => {
  return {
    width: "100%",
    "& input": {
      textAlign: "left",
      fontSize: "1.1em",
    },
    "& label": {
      fontSize: "1.1em",
      "& fieldset": { border: "none" },
    },
  };
};

const labelFields_style = () => {
  return { fontSize: "1em", color: "#8f8f8f", padding: "0 0 0 0.2em", fontWeight: "1em", align: "left" };
};

export default function SideBarEditableContent_style({ editMode, setEditMode, isClosed }) {
  // Atom states
  const [modelInterventionState, setModelInterventionState] = useAtom(modelInterventionAtom);
  const [manufacturerInterventionState, setManufacturerlInterventionState] = useAtom(manufacturerInterventionAtom);

  const [plateInterventionState, setPlateInterventionState] = useAtom(plateInterventionAtom);
  const [clientNameState, setClientNameState] = useAtom(clientNameAtom);
  const [clientSurnameState, setClientSurnameState] = useAtom(clientSurnameAtom);
  const [dateInterventionState, setDateInterventionState] = useAtom(dateInterventionAtom);
  const [kmInterventionState, setKmInterventionState] = useAtom(kmInterventionAtom);
  const [jwtState, setJwtState] = useAtom(tokenAtom);

  // Local states

  const [modelState, setModelState] = useState();
  const [manufacturerState, setManufacturerState] = useState();
  const [manufacturersListState, setManufacturersListState] = useState();
  const [modelsState, setModelsState] = useState();
  const [plateState, setPlateState] = useState();
  const [nameState, setNameState] = useState();
  const [surnameState, setSurnameState] = useState();
  const [dateState, setDateState] = useState();
  const [kmState, setKmState] = useState();

  const [loadingState, setLoadingState] = useState(true);



  useEffect(() => {
    const token = getStorageObject("token") || jwtState;

      getAllManufacturers(token)
        .then(({ data: manufacturers }) => {

          getAllModels(token)
            .then(({ data: models }) => {

              const transformedModels = models.map((model) => {
                return {
                  id: model.id,
                  value: model.id,
                  label: model.name,
                  manufacturer: model.id_vehicle_manufacturer,
                };
              });
              setModelsState(transformedModels);
              const capitalize = (str) => str.charAt(0).toUpperCase() + str.slice(1);
              const transformedManufacturers = manufacturers.map((manufacturer) => {
                const value = manufacturer.name;
                return {
                  id: manufacturer.id,
                  value: manufacturer.id,
                  label: capitalize(value),
                };
              });

              setManufacturersListState(transformedManufacturers);
              setLoadingState(false);
            });
          
        });
        setLoadingState(false);
    

  }, []);



  useEffect(() => {

    if (modelsState) {
    setManufacturerState(manufacturersListState.find(manufacturer => manufacturer.id === manufacturerInterventionState) || null)
    setModelState(modelsState.find(model => model?.id === modelInterventionState) || null);
  }}, [modelsState, editMode]);


  useEffect(() => {

    if (plateInterventionState) {
      setPlateState(plateInterventionState);
    }

    if (clientNameState) {
      setNameState(clientNameState);
    }

    if (clientSurnameState) {
      setSurnameState(clientSurnameState);
    }

    if (dateInterventionState) {
      setDateState(dateInterventionState);
    }

    if (kmInterventionState) {
      setKmState(kmInterventionState);
    }

  }, [editMode]);

  useEffect(() => {
    if (!isClosed) {
      if (interventionEditModeAtom)
      if (modelInterventionState == "[object Object]" || false) {
        setEditMode(true);
      }
    }
  }, [modelInterventionState, modelsState]);

  const handleSaveChanges = () => {

    setModelInterventionState(modelState.value);
    setManufacturerlInterventionState(manufacturerState.value);
    setPlateState(plateState);
    setClientNameState(nameState);
    setClientSurnameState(surnameState);
    setDateInterventionState(dateState);
    setKmInterventionState(kmState);
    setEditMode(false);
  };

  const handleChange_FieldChange = (_event, _value, setState) => setState(_value ? _value : _event?.target?.value);
  const handleChange_AutocompleteField = (event, value) => { setManufacturerState(value); setModelState(null) };
  const handleChange_Model_AutocompleteField = (event, value) => { setModelState(value) };

  const errorValidator = (value) => {
    if (value) return false;
    else return true;
  };

  const handleButtonAvailability = () => {
    if (plateState && nameState && dateState && kmState && modelState && manufacturerState) {
      return false;
    } else return true;
  };

  function formatDate(date) {
    const dateObj = new Date(date);
    const day = ("0" + dateObj.getDate()).slice(-2);
    const month = ("0" + (dateObj.getMonth() + 1)).slice(-2);
    const year = dateObj.getFullYear();
    return `${day}-${month}-${year}`;
  }

  if (loadingState) return <div>Cargando...</div>;

  return (
    <>
      {editMode ? (
        <>
          <Grid container sx={{ p: 0, display: "flex", height: "fit-content", flexDirection: "column", width: "100%", justifyContent: "center", p: "15px" }}>
            <Autocomplete
              error={errorValidator(manufacturerState)}
              disablePortal
              clearOnBlur
              onChange={handleChange_AutocompleteField}
              options={manufacturersListState}
              xs={6}
              sm={4}
              xl={5}
              sx={{ width: "90%", textAlign: "center", justifyContent: "center", fontSize: "2em", fontWeight: "bolder", border: !manufacturerState && "1px solid red" }}
              value={manufacturerState}
              renderInput={(params) => <TextField {...params} label="Marca" variant="standard" />}
            />
            {manufacturerState && (
              <Autocomplete
                error={errorValidator(manufacturerState)}
                disablePortal
                clearOnBlur
                onChange={handleChange_Model_AutocompleteField}
                options={modelsState.filter((model) => model?.manufacturer === manufacturerState?.id)}
                xs={6}
                sm={4}
                xl={5}
                sx={{ width: "90%", textAlign: "center", justifyContent: "center", fontSize: "2em", fontWeight: "bolder", border: !modelState && "1px solid red" }}
                value={modelState}
                renderInput={(params) => <TextField {...params} label="Modelo" variant="standard" />}
              />
            )}
          </Grid>
          <Grid container sx={{ display: "flex", justifyContent: "space-between" }}>
            <Grid item sx={{ p: "15px", width: "50%" }}>
              <TextField
                value={plateState}
                error={errorValidator(plateState)}
                helperText={errorValidator(plateState) && "Campo obligatorio"}
                sx={{ textFields_style, border: !plateState && "1px solid red" }}
                required
                fullWidth
                variant="standard"
                label="Matricula"
                onChange={(event) => handleChange_FieldChange(event, null, setPlateState)}
              />
            </Grid>
            <Grid item sx={{ p: "15px", width: "50%" }}>
              <TextField
                value={kmState}
                error={errorValidator(kmState)}
                helperText={errorValidator(kmState) && "Campo obligatorio"}
                required
                id="kmsIntervention"
                name="kmsIntervention"
                fullWidth
                type="number"
                variant="standard"
                label="Kilometraje"
                sx={{ border: !kmState && "1px solid red" }}
                InputProps={{
                  endAdornment: <InputAdornment position="end">KM</InputAdornment>,
                }}
                onChange={(event) => handleChange_FieldChange(event, null, setKmState)}
              />
            </Grid>
          </Grid>
          <Grid container sx={{ display: "flex", justifyContent: "space-between" }}>
            <Grid item sx={{ p: "15px", width: "50%", height: "fit-content" }}>
              <TextField
                value={nameState}
                sx={{ textFields_style, border: !nameState && "1px solid red" }}
                error={errorValidator(nameState)}
                helperText={errorValidator(nameState) && "Campo obligatorio"}
                required
                id="clientNameField"
                name="clientNameField"
                variant="standard"
                label="Nombre"
                onChange={(event) => handleChange_FieldChange(event, null, setNameState)}
              />
            </Grid>
            <Grid item sx={{ p: "15px", width: "50%", height: "fit-content" }}>
              <TextField
                value={surnameState}
                sx={{ textFields_style, border: !surnameState && "1px solid red" }}
                error={errorValidator(surnameState)}
                helperText={errorValidator(surnameState) && "Campo obligatorio"}
                id="clientSurnameField"
                name="clientSurnameField"
                variant="standard"
                label="Apellido"
                onChange={(event) => handleChange_FieldChange(event, null, setSurnameState)}
              />
            </Grid>
          </Grid>
          <Grid container sx={{ width: "100%", display: "flex", alignItems: "center", justifyContent: "flex-start" }}>
            <Grid item xs={6} sx={{ p: "15px" }}>
              <TextField
                error={errorValidator(dateState)}
                helperText={errorValidator(dateState) && "Campo obligatorio"}
                value={dateState}
                variant="standard"
                label="Fecha de intervención"
                sx={{ textFields_style, border: !dateState && "1px solid red" }}
                required
                id="dateIntervention"
                fullWidth
                type="date"
                onChange={(event) => handleChange_FieldChange(event, null, setDateState)}
              />
            </Grid>
            <Grid item xs={6} sx={{ p1: "15px" }}>
              <Button disabled={handleButtonAvailability()} variant="contained" onClick={handleSaveChanges} sx={{ m: "15px", width: "85%" }}>
                Guardar
              </Button>
            </Grid>
          </Grid>
        </>
      ) : (
        <>
          <Grid container sx={{ p: 0, display: "flex", height: "fit-content", flexDirection: "column", width: "100%", justifyContent: "center", paddingLeft: "0.8em", flexWrap: "no wrap" }}>
            <Grid container sx={{ display: "flex", width: "100%" }}>
              <Grid item xs={8}>
                {manufacturerInterventionState && (
                  <Typography noWrap sx={{ display: "flex", fontSize: "2.75rem", fontWeight: "800", flexWrap: "nowrap", overflow: "hidden", textOverflow: "ellipsis", width: "133%", textWrap: "nowrap" }}>
                    {`${manufacturersListState && manufacturersListState.find(manufacturer => manufacturer.id === manufacturerInterventionState)?.label || ""}`} {`${modelsState && modelsState.find(model => model.id === modelInterventionState)?.label || ""}`}
                  </Typography>
                )}
              </Grid>
            </Grid>

            <Grid item xs={6}>
              <Typography sx={{ fontSize: "1.1rem", fontWeight: "550", pl: "0.2em" }} textAlign="left">
                {plateInterventionState}
              </Typography>
            </Grid>
            <Grid item xs={6} sx={{ marginBottom: "0.4em" }}>
              <Typography sx={{ fontSize: "1.1rem", pl: "0.2em", fontWeight: "550" }} textAlign="left">
                {kmInterventionState && kmInterventionState?.toLocaleString()} Km
              </Typography>
            </Grid>

            <Grid container xs={12} sx={{ display: "flex", alignItems: "center", width: "100%", justifyContent: "space-between" }}>
              <Typography sx={labelFields_style}>Cliente</Typography>
              <Grid item>
                <Typography sx={{ ...textFields_style, display: "flex", fontSize: "1.3rem" }}>
                  {" "}
                  {clientNameState && `${clientNameState}`} {clientSurnameState && ` ${clientSurnameState}`}
                </Typography>
              </Grid>
            </Grid>

            <Grid container xs={12} sx={{ display: "flex", alignItems: "center", width: "100%", justifyContent: "space-between" }}>
              <Typography sx={labelFields_style}>Fecha de intervención</Typography>
              <Grid item>
                <Typography sx={{ ...textFields_style, display: "flex", fontSize: "1.3rem" }}>{dateInterventionState && formatDate(dateInterventionState)}</Typography>
              </Grid>
            </Grid>
          </Grid>
        </>
      )}
    </>
  );
};
