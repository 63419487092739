import React from 'react'
import { useEffect, useState } from 'react'
import HeaderComponent from '../../components/Header.Component.jsx'
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
import MainContent from '../../components/MainContent.component';
import { getAllRepairshops } from '../../services/API/User.Services.js';
import { getStorageObject, setStorageObject } from '../../utils/LocalStorage.js';
import DataTable from './components/DataTable.jsx';
import LoadingComponent from '../../components/Loading.Component.jsx';
import { Container, Stack } from '@mui/material';
import { setWorkshopUserImpersonation } from '../../services/API/Admin.Services.js';
import { useNavigate } from 'react-router-dom';

const AdministratorPage = () => {
  const navigate = useNavigate();

  const [repairshopsState, setRepairshopsState] = useState([])
  const [filteredRepairshopsState, setFilteredRepairshopsState] = useState([])
  const [searchValueState, setSearchValueState] = useState('')

  const handleWorkshopClick = (workshop) => {

    if (!getStorageObject("adminSessionToken")) {
      const adminSessionToken = getStorageObject("token");
      setStorageObject("adminSessionToken", adminSessionToken);
    }

    const token = getStorageObject("adminSessionToken")
    setWorkshopUserImpersonation(token, workshop)
      .then(({ data }) => {
        setStorageObject("userData", workshop)
        setStorageObject("repairShopId", workshop.id_user_repairshop);
        setStorageObject("token", data.token);
        navigate("/clientes");
        location.reload();
      }).catch((error) => {
        console.error(error)
      });
  }

  const [loadingState, setLoadingState] = useState(true)

  useEffect(() => {
    if (!getStorageObject("adminSessionToken")) {
      const adminSessionToken = getStorageObject("token");
      setStorageObject("adminSessionToken", adminSessionToken);
    }

    const token = getStorageObject("adminSessionToken")

    getAllRepairshops(token)
      .then(({ data: repairshops }) => {
      
        setRepairshopsState(repairshops)
        setFilteredRepairshopsState(repairshops)
        setLoadingState(false)
      })
  }, [])

  useEffect(() => {
    if (searchValueState) {
      const filteredRepairshopsState = repairshopsState.filter((repairshop) => JSON.stringify(repairshop).toLocaleLowerCase().includes(searchValueState.toLowerCase()));
      setFilteredRepairshopsState(filteredRepairshopsState);
    }
  }, [searchValueState]);

  if (loadingState) {
    return <LoadingComponent />
  }

  return (
    <MainContent>
      <Stack direction="column" gap="30px" width="100%" sx={{ margin: "0" }}>
        <HeaderComponent text="Administrador" icon={<SupervisorAccountIcon sx={{ color: "#e94e1b", height: "40px", width: "40px" }} />} />
        <Container sx={{ padding: "0px 10px 10px 10px" }}>
          <DataTable loadingState={loadingState} setLoadingState={setLoadingState} handleWorkshopClick={handleWorkshopClick} repairshops={filteredRepairshopsState} searchValue={searchValueState} setSearchValue={setSearchValueState} />
        </Container>
      </Stack>
    </MainContent>
  );
}

export default AdministratorPage