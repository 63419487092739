import * as React from "react";
import { useState, useEffect } from "react";

import Typography from "@mui/material/Typography";
import { changedItemsAtom, reviewsAtom, userVehicleAtom } from "../../../context/atoms/Atoms.Storage";

import { useAtom } from "jotai";
import { Grid } from "@mui/material";
import InitChangedItems_State from "./schemes/PartsFields.scheme";

const color = "confirmed" | "empty";

const circulo_style = (color) => {
  return {
    width: "25px",
    height: "25px",
    backgroundColor: color === "confirmed" ? "#20c20040" : "#f8f8f8",
    borderRadius: "50%",
    position: "relative",
    border: "1px solid #808080",
  };
};

const texto_style = {
  width: "200px",
  position: "absolute",
  top: "50%",
  left: "30px",
  textAlign: "left",
  transform: "translateY(-50%)",
};

const itemVariant_style = (variant) => {
  return {
    background: variant === "correct" ? "#f6f6f6" : "#f6f6f6", //#20c20040
  };
};

export default function CheckoutComponent({carcomponents}) {
  const { PiezasOptionsFieldsArray } = InitChangedItems_State();
  
  const [userVehicleState, setUserVehicleState] = useAtom(userVehicleAtom);
  const [changedItemsState, setChangedItemsState] = useAtom(changedItemsAtom);
  const [reviewsState, setReviewsState] = useAtom(reviewsAtom);

  const [interventionPartsState, setInterventionPartsState] = useState();

  useEffect(() => {
    const _scheme = [...carcomponents];
    const joinedParts = [...Object.values(changedItemsState.filter((x) => x.id !== "")), ...Object.values(reviewsState.filter((x) => x.id !== ""))];

    _scheme.forEach((scheme_element) => {
      joinedParts.forEach((changedItemm_element) => {
        if (scheme_element.id === changedItemm_element.id) {
          if (scheme_element.piece === true) {
            scheme_element.fields[0].caducidadMeses = changedItemm_element.caducidadMeses;
            scheme_element.fields[0].caducidadKm = changedItemm_element.caducidadKm;
          } else if (scheme_element.piece === false) {
            scheme_element.fields[0].status = changedItemm_element.status;
            scheme_element.fields[0].isReplaced = changedItemm_element.isReplaced;
          }
        }
      });
    });

    _scheme.forEach((scheme_element) => {
      if (scheme_element.piece === true) {
        if (!scheme_element.fields[0].caducidadMeses && !scheme_element.fields[0].caducidadKm) {
          scheme_element.variant = "disabled";
        } else {
          scheme_element.variant = "correct";
        }
      }
    });

    setInterventionPartsState(_scheme);
  }, [changedItemsState, reviewsState]);

  const pieceBackgroundColor = (x) => {
    if (x.status === 2) {
      return "#e73d3d";
    } else if (x.status === 1) {
      return "#fff2e6";
    } else if (x.status === 0) {
      return "#f4fffc";
    }
  };

  const pieceBorderColor = (x) => {
    if (x.status === 2) {
      return "2px solid #e73d3d";
    } else if (x.status === 1) {
      return "2px solid #f48c0b";
    } else if (x.status === 0) {
      return "2px solid #4abe48";
    } else {
      return "2px solid transparent";
    }
  };

  const pieceTextColor = (x) => {
    if (x.status === 2) {
      return "#fff";
    } else if (x.status === 1) {
      return "#f48c0b";
    } else if (x.status === 0) {
      return "#4abe48";
    } else {
      return "black";
    }
  };

  return (
    <React.Fragment>
      <Grid container xs={12} sx={{ overflow: "auto", margin: "00 " }}>
        <Grid item xs={12} sx={{ marginBottom: "0.6em" }}>
          <Typography variant="h8" sx={{ fontWeight: "bold" }}>
            Resumen de las Piezas
          </Typography>
        </Grid>
        <Grid
          container
          xs={12}
          sx={{
            height: "-webkit-fill-available",
            overflow: "auto",
            padding: "1em",
            justifyContent: "left",
          }}
        >
          {interventionPartsState?.map((x, index) => {
            if (x.piece === true && (x.fields[0].caducidadKm || x.fields[0].caducidadMeses)) {

              if (x.id !== "") {
                const reviewlabels = ["Óptimo", "Regular", "Deficiente", "No informado"];

                return (
                  <Grid key={index} item xs={4} sx={{ padding: "0 0.2em 0 0.2em" }}>
                    <Grid
                      item
                      xs={12}
                      align="center"
                      height={"4em"}
                      sx={{
                        ...itemVariant_style(x.variant),
                        borderRadius: "5px",
                        marginBottom: "1em",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between!important",
                        textWrap: "wrap",

                        paddingLeft: "0.5em",
                      }}
                    >
                      <Typography
                        gutterBottom
                        sx={{
                          textAlign: "left",
                          padding: "0.5em 0 0 0.5em",
                          fontWeight: "bold",
                          width: "60%",
                          lineHeight: "1.25em",
                        }}
                      >
                        {x.label}
                      </Typography>
                      {x.piece === true && (
                        <Grid container sx={{ p: "0 0 0 0.5em", display: "flex", alignItems: "center", justifyContent: "flex-end", paddingRight: "0.5em" }}>
                          <Typography
                            xs={6}
                            gutterBottom
                            sx={{
                              textAlign: "left",
                              margin: "0 0.5em 0 0em",
                              padding: "0.2em 0.5em",
                              color: "#828282",
                              border: "2px solid #d8d6e5",
                              borderRadius: "5px",
                              textWrap: "wrap",
                              overflow: "hidden",
                              width: "fit-content",
                              textWrap: "nowrap",
                            }}
                          >
                            {`${x.fields[0].caducidadMeses} M`}
                          </Typography>
                          <Typography
                            xs={6}
                            gutterBottom
                            sx={{
                              textAlign: "left",
                              margin: "0 0.5em 0 0.5em",
                              padding: "0.2em 0.5em",
                              color: "#828282",
                              border: "2px solid #d8d6e5",
                              borderRadius: "5px",
                              textWrap: "wrap",
                              overflow: "hidden",
                              width: "fit-content",
                              textWrap: "nowrap",
                            }}
                          >
                            {`${x?.fields[0]?.caducidadKm?.toLocaleString()} Km`}
                          </Typography>
                        </Grid>
                      )}
                    </Grid>
                  </Grid>
                );
              }
            }
          })}
        </Grid>

        <Grid item xs={12} sx={{ marginBottom: "0.7em" }}>
          <Typography variant="h8" sx={{ fontWeight: "bold" }}>
            Resumen de las Revisiones
          </Typography>
        </Grid>
        <Grid
          container
          xs={12}
          sx={{
            height: "-webkit-fill-available",
            overflow: "auto",
            padding: "1em",
            justifyContent: "left",
          }}
        >
          {interventionPartsState?.map((x) => {
            if (x.piece === false && x.fields[0].status >= 0 && x.fields[0].status < 3) {
              if (x.id !== "") {
                const reviewlabels = ["Óptimo", "Regular", "Deficiente", "No informado"];

                return (
                  <Grid item xs={4} sx={{ padding: "0 0.2em 0 0.2em" }}>
                    <Grid
                      item
                      xs={12}
                      align="center"
                      margin={"0 0.2em 0 0 "}
                      height={"3.2em"}
                      sx={{
                        ...itemVariant_style(x.variant),
                        borderRadius: "5px",
                        marginBottom: "1em",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        paddingLeft: "0.5em",
                      }}
                    >
                      <Typography
                        xs={6}
                        gutterBottom
                        sx={{
                          textAlign: "left",
                          padding: "0.5em 0 0 0.5em",
                          fontWeight: "bold",
                        }}
                      >
                        {x.label}
                      </Typography>
                      {x.piece === false && (
                        <Typography
                          xs={6}
                          sx={{
                            height: "1.5em",
                            textAlign: "left",
                            margin: "0 1em 0 0.5em",
                            padding: "0 0.5em 0 0.5em",
                            background: x.fields[0].isReplaced ? "#4abe48" : pieceBackgroundColor(x.fields[0]),
                            border: pieceBorderColor(x.fields[0]),
                            color: x.fields[0].isReplaced ? "white" : pieceTextColor(x.fields[0]),
                            borderRadius: "5px",
                            width: "fit-content",
                          }}
                        >
                          {x.fields[0].isReplaced ? "Reemplazado" : reviewlabels[x.fields[0].status]}

                  
                        </Typography>
                      ) }
                    </Grid>
                  </Grid>
                );
              }
            }
          })}
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
